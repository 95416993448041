.containerStyleDefault {
  height: 100%;
  overflow: hidden;
}

// Overrides containerStyleDefault properties
.containerStyleAutoHeight {
  height: auto;
}

.scrollTrack {
  border-radius: 0.2rem;
  bottom: 0.15rem;
  cursor: pointer;
  display: block;
  height: 100%;
  position: absolute;
  right: 0.15rem;
  top: 0.15rem;
  width: 1rem;
}

.scrollBar {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: inherit;
  cursor: pointer;
  display: block;
  height: 100%;
  position: relative;
  transform: translateY(0);
  width: 100%;
  z-index: 1000;
}

.scrollView {
  max-height: 100%;
  overflow-y: scroll;
}

.disableSelect {
  user-select: none;
}

