@import 'variables';

.pageContainer {
  display: flex;
  min-height: 100%;
}

.sidebarContainer {
  flex: none;
  flex-basis: auto;
  position: relative;
}

.viewContainer {
  flex-grow: 1;
  width: 100%;
}
