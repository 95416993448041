@import 'variables';
@import 'mixins/boxShadowHelper';

$infoHeight: 1.262rem;
$modalBackground: rgba($backgroundColour, 0.3);

.overlay {
  align-items: center;
  background-color: $overlayBackground;
  //border is used here so we can size off the border
  border: 1rem solid transparent;
  border-bottom-width: 1.5rem;
  border-top-width: 1.5rem;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5000;
}

.modal {
  @include boxShadowHelper(5);

  background-color: $modalBackground;
  display: inline-block;

  &:hover {
    .info > div {
      height: $infoHeight;
      padding: 0.1rem 0.2rem;
    }
  }
}

.photoHolder {
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  transition: width $transition, $transition;
  will-change: height, width;

  .photo {
    display: block;
    opacity: 0;
    user-select: none;
    will-change: opacity;

    &.visible {
      opacity: 1;
      transition: opacity $transition;
    }
  }

  .previous,
  .next {
    height: 100%;
    position: absolute;
    top: 0;
    width: 50%;
  }

  .previous {
    left: 0;
  }

  .next {
    right: 0;
  }
}

.info {
  height: 0;

  > div {
    @include boxShadowHelper(5);

    background-color: $modalBackground;
    height: 0;
    overflow: hidden;
    padding: 0 0.2rem;
    transition: height $transition, padding $transition;
    will-change: height, padding;
  }
}

.downloadImage {
  color: $mainColour;
  padding-right: 0.5rem;

  // Needed for hover effect on path
  // stylelint-disable max-nesting-depth
  &:hover {
    svg {
      path {
        fill: rgba($mainColour, 0.5);
      }
    }
  }
  // stylelint-enable max-nesting-depth

  svg {
    height: 0.9rem;
    width: 0.9rem;

    path {
      fill: $mainColour;
    }
  }
}
