@import 'variables';

.headingUnderlinedCentred,
.headingUnderlined {
  margin: 0.67rem #{$h1FontSize / 3} 0;
  overflow: hidden;

  h1 {
    font-size: $h1FontSize;
    margin: 0;
    white-space: nowrap;

    &.visibleMobile {
      display: none;
      font-size: $h1FontSize / 1.5;
    }
    @media (max-width: 800px) {
      font-size: $h1FontSize / 1.5;
    }
    @media (max-width: 550px) {
      &.hiddenMobile {
        display: none;
      }
    }

    &::before,
    &::after {
      border-color: $mainColour;
      border-style: solid;
      border-width: #{$h1FontSize/9.58} 0;
      content: '';
      height: 0.7rem;

    }
  }
}

.headingUnderlined {
  h1 {
    width: 100%;
    @media (max-width: 550px) {
      &.visibleMobile {
        display: block;
      }
    }

    &::before {
      width: $h1FontSize / 2;
    }

    &::after {
      width: 100%;
    }

    &::before,
    &::after {
      display: inline-block;
    }
  }
}

.headingUnderlinedCentred {
  text-align: center;

  h1 {
    display: inline-block;
    position: relative;
    @media (max-width: 550px) {
      &.visibleMobile {
        display: inline-block;
      }
    }

    &::before {
      margin-right: 0;
      right: 100%;
    }

    &::after {
      left: 100%;
      margin-left: 0x;
    }

    &::before,
    &::after {
      bottom: 0.65rem;
      position: absolute;
      width: 6000px;
    }
  }
}
