.appear {
  opacity: 0.01;

  &.appearActive {
    opacity: 1;
    transition: opacity 500ms ease;
  }
}

.enter {
  opacity: 0.01;

  &.enterActive {
    opacity: 1;
    transition: opacity 500ms ease;
  }
}

.exit {
  opacity: 1;

  &.exitActive {
    opacity: 0.01;
    transition: opacity 500ms ease;
  }
}
