@import 'variables';


.picture {
  background: url('../ContactView/profile-picture.jpg') no-repeat center center;
  background-size: cover;
  border-radius: 100%;
  display: block;
  height: 50vw;
  margin: 0.67rem auto 0;
  max-height: 270px;
  max-width: 270px;
  width: 50vw;
}

.tagLine {
  display: block;
  font-size: $h1FontSize / 2;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.social {
  display: block;
  margin: 0 auto;
  max-width: 800px;
  padding: 0;
  text-align: center;
  white-space: nowrap;

  li {
    display: inline-block;
    position: relative;

    &:nth-child(n+2)::before {
      content: '\2022';
      font-weight: bold;
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

