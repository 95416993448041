@import 'variables';
@import 'mixins/boxShadowHelper';

$margin: 0.3rem;
$padding: 0.3rem;
$border: 0.1rem;
$pulsateColour: #184c71;

$borderColour: $mainColour;
$paddingColour: $backgroundColour;

@mixin borderBox {

  backface-visibility: hidden;
  background: $paddingColour;
  border: solid $border $borderColour;
  cursor: pointer;
  padding: $padding;

  &:hover {
    transform: scale(1.025) translate3d(0, 0, 0);
    transition: transform 100ms ease-in-out;
  }
}

@mixin flexBasis($reduction) {
  .photoBox {
    &.tripleWidth {
      width: 300% / $reduction;
    }

    &.doubleWidth {
      width: 200% / $reduction;
    }

    &.double {
      width: 200% / $reduction;
    }

    &.oneToOne,
    &.doubleHeight,
    &.tripleHeight,
    &.extrashort#{$reduction} {
      width: 100% / $reduction;
    }

    &.short#{$reduction} {
      width: 200% / $reduction;

      > .rowSizer {
        padding-top: calc(50% - #{$margin});
      }
    }

    &.extrashort#{$reduction} {
      width: 100% / $reduction;

      > .rowSizer {
        padding-top: 100%;
      }
    }
  }
}

@mixin bounceFrames($percent, $bounces, $scale) {
  @if $percent < 100 {
    @include bounceFrames($percent+100/$bounces, $bounces, $scale*-0.75);

    #{$percent * 1%} {
      transform: scale(1+$scale) translate3d(0, 0, 0);
    }
  } @else {
    #{100%} {
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1) translate3d(0, 0, 0);
  }
  // @include bounceFrames(5, 5, -0.075)
  0% {
    transform: scale(1) translateZ(0);
  }

  5% {
    transform: scale(0.925) translateZ(0);
  }

  25% {
    transform: scale(1.05625) translateZ(0);
  }

  45% {
    transform: scale(0.95781) translateZ(0);
  }

  65% {
    transform: scale(1.03164) translateZ(0);
  }

  85% {
    transform: scale(0.97627) translateZ(0);
  }

  100% {
    transform: scale(1) translateZ(0);
  }
}

// .rowNumber{
//   border: $border*6 solid crimson;
//   top:0.3rem;
//   right: 25%;
//   width: 9rem;
//   visibility: visible;
//   background: white;
//   position: absolute;
//   margin-left: 3rem;
// }
// .spacer3, .spacer6, .spacer9, .spacer12{
//   .rowNumber{
//     background-color: dodgerblue;
//   }
// }

.photoBox {
  flex: none;
  margin: 0;
  //this is the outside of the photo gap so hence margin :)
  padding: $margin;
  position: relative;

  > .rowSizer {
    padding-top: 100%;
    position: relative;
  }

  .imageCrop {
    @include borderBox;
    @include boxShadowHelper(1);

    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    svg {
      left: calc(50% - 1.5rem);
      position: absolute;
      top: calc(50% - 1.5rem);
    }
  }

  .bounce {
    animation: bounce 0.5s ease-in-out;
  }

  .image {
    background-position: center;
    background-size: cover;
    height: 100%;
    overflow: hidden;
  }

  &.doubleWidth {
    > .rowSizer {
      padding-top: calc(50% - #{$margin});
    }
  }

  &.tripleWidth {
    > .rowSizer {
      padding-top: calc((100%/3) - #{$margin*4/3});
    }
  }

  &.doubleHeight {
    .imageCrop {
      height: calc(200% + #{$margin*2});
    }
  }

  &.tripleHeight {
    .imageCrop {
      height: calc(300% + #{$margin*4});
    }
  }

  &.double {
    > .rowSizer {
      padding-top: calc(50% - #{$padding});
    }

    .imageCrop {
      height: calc(200% + #{$padding*2});
    }
  }
}

.photoHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
}

.spacer3,
.spacer6,
.spacer9,
.spacer12 {
  display: none;
  visibility: hidden;

  > .rowSizer {
    height: 0;
  }
}
@media (max-width: 767px) {
  @include flexBasis(3);

  .spacer3 {
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  @include flexBasis(6);

  .spacer6 {
    display: block;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  @include flexBasis(9);

  .spacer9 {
    display: block;
  }
}

@media (min-width: 1200px) {
  @include flexBasis(12);

  .spacer12 {
    display: block;
  }
}
