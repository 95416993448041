@import 'variables';
@import 'mixins/boxShadowHelper';

@mixin menuLevelsMixin($colour, $highlightColour, $level: 1) {
  $hoverChange: 1.5%;
  $activeChange: 4%;

  .level#{$level} {
    background-color: $colour;

    // We seem to have a 1px border that the shadow doesn't cover so we hide it with this.
    polygon {
      stroke: $colour;
    }

    > a {
      z-index: 100 - $level;

      &:hover {
        background-color: darken($colour, $hoverChange);

        // We seem to have a 1px border that the shadow doesn't cover so we hide it with this.
        // stylelint-disable-next-line max-nesting-depth
        polygon {
          stroke: darken($colour, $hoverChange);
        }
      }

      &.navLink:hover {
        @include boxShadowHelper(1);
      }

      &.parentLink:hover::after {
        @include boxShadowHelper(1);
      }

      &.parentLink {
        // stylelint-disable-next-line max-nesting-depth
        &.openLink:hover::after {
          box-shadow: none;
        }
      }
    }

    > .activeLink {
      background-color: darken($colour, $activeChange);
      border-left-color: $highlightColour;

      // We seem to have a 1px border that the shadow doesn't cover so we hide it with this.
      polygon {
        stroke: darken($colour, $activeChange);
      }

      &:hover {
        background-color: darken(darken($colour, $activeChange), $hoverChange);
        border-left-color: $highlightColour;

        // We seem to have a 1px border that the shadow doesn't cover so we hide it with this.
        // stylelint-disable-next-line max-nesting-depth
        polygon {
          background-color: darken(darken($colour, $activeChange), $hoverChange);
        }
      }
    }
  }

  @if $level != 5 {
    @include menuLevelsMixin(
      lighten($colour, 6%),
      lighten($highlightColour, 3%),
      $level + 1);
  }
}

@include menuLevelsMixin($altColour, $highlightColour);


$sideBarWidth: 2.75rem;
$hamburgerWidth: $sideBarWidth - 1rem;
$fontColour: darken($backgroundColour, 20%);

.sideBar {
  background-color: $mainColour;
  width: $sideBarWidth;
}

.overlay {
  background-color: $overlayBackground;
  cursor: pointer;
  height: 100%;
  left: $hamburgerWidth + 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  will-change: opacity;
  z-index: 1001;
}

.track {
  @include boxShadowHelper(3);

  background-color: $mainColour;
  height: 101%;
  position: fixed;
  z-index: 1003;

  .hamburger {
    cursor: pointer;
    display: block;
    height: $hamburgerWidth;
    margin: 1rem 0.5rem 0.5rem;
    transition: transform $transition;
    width: $hamburgerWidth;
    will-change: transform;

    &.open {
      transform: rotate(180deg);
    }

    rect {
      fill: $fontColour;
    }
  }
}

.menu {
  @include boxShadowHelper(3);

  background-color: $altColour;
  height: 100%;
  left: -22.2rem;
  position: fixed;
  transition: left $transition;
  width: 22.2rem;
  will-change: left;
  z-index: 1002;

  &.open {
    left: -1px;
  }

  .menuRoot {
    margin: 0 0 0 $sideBarWidth;
    padding: 0.5rem 0 0;
  }

  li {
    list-style: none;

    > ul {
      height: 0;
      overflow: hidden;
      padding: 0;
      transition: height $transition;
      will-change: height;
    }
  }

  a {
    border-left: 1rem transparent solid;
    color: $fontColour;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    padding: 0.7rem 1rem;
    position: relative;
    text-decoration: none;
    transition: box-shadow $transition, background-color 0.1s ease;
    user-select: none;
    width: 100%;
    will-change: box-shadow;

    &::first-letter {
      text-transform: capitalize;
    }

    &.navLink:hover,
    &.parentLink::after {
      transition: box-shadow 0.1s ease, background-color 0.1s ease;
    }

    &.parentLink::after {
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: -1rem;
      position: absolute;
      right: 0;
    }

  }

  .activeLink {
    @include boxShadowHelper(1);
  }

  .openLink {
    @include boxShadowHelper(3);
  }

  .triangle {
    height: 1.3rem;
    position: absolute;
    right: 1.3rem;

    polygon {
      fill: $fontColour;
      transform-origin: 50% 50%;
      will-change: transform;
    }
  }
}
