@font-face {
  font-family: 'bebas_neue_regular';
  font-style: normal;
  font-weight: normal;
  src: url('fonts/BebasNeueRegular/bebasneue_regular-webfont.eot');
  src: url('fonts/BebasNeueRegular/bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/BebasNeueRegular/bebasneue_regular-webfont.woff2') format('woff2'),
    url('fonts/BebasNeueRegular/bebasneue_regular-webfont.woff') format('woff'),
    url('fonts/BebasNeueRegular/bebasneue_regular-webfont.ttf') format('truetype');
}

:global {
  @import 'base';
  @import '~normalize-scss/sass/normalize';

  // Apply standed styling to the document
  html {
    box-sizing: border-box;
    color: $fontColour;
    font-family: 'bebas_neue_regular';
    font-size: 16px;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  //set 100% on the containers
  // stylelint-disable selector-max-id
  #root {
    height: 100%;

    > div {
      height: 100%;
    }
  }
  // stylelint-enable selector-max-id
}
