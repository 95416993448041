@mixin boxShadowHelper($level: 1, $inset: false) {
  $inset: '';
  @if $inset == true {
    $inset: 'inset';
  }

  @if $level == 1 {
    box-shadow: #{$inset} 0 1px 3px rgba(0, 0, 0, 0.12), #{$inset} 0 1px 2px rgba(0, 0, 0, 0.24);
  } @else if $level == 2 {
    box-shadow: #{$inset} 0 3px 6px rgba(0, 0, 0, 0.16), #{$inset} 0 3px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 3 {
    box-shadow: #{$inset} 0 10px 20px rgba(0, 0, 0, 0.19), #{$inset} 0 6px 6px rgba(0, 0, 0, 0.23);
  } @else if $level == 4 {
    box-shadow: #{$inset} 0 14px 28px rgba(0, 0, 0, 0.25), #{$inset} 0 10px 10px rgba(0, 0, 0, 0.22);
  } @else if $level == 5 {
    box-shadow: #{$inset} 0 19px 38px rgba(0, 0, 0, 0.3), #{$inset} 0 15px 12px rgba(0, 0 , 0, 0.22);
  }
}
