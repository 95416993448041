$h1FontSize: 3rem;

$backgroundColour: white;
$mainColour: #222;
$altColour: #2b2b2b;
$highlightColour: #0b486b;

$fontColour: $mainColour;

$overlayBackground: rgba(255, 255, 255, 0.75);

$transition: 0.5s ease;
