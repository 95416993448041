@import 'variables';

$baseColour: $mainColour;

.svg {
  height: 3rem;
  width: 3rem;
}

.layer {
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  fill: $baseColour;
  will-change: opacity;

}

.layer1 {
  animation-name: animation1;
}

.layer2 {
  animation-name: animation2;
}

.layer3 {
  animation-name: animation3;
}

@keyframes animation1 {
  0%,
  65% {
    opacity: 1;
  }

  75%,
  82% {
    opacity: 0;
  }

  92%,
  100% {
    opacity: 1;
  }
}

@keyframes  animation2 {
  0%,
  40% {
    opacity: 1;
  }

  50%,
  86% {
    opacity: 0;
  }

  96%,
  100% {
    opacity: 1;
  }
}

@keyframes  animation3 {
  0%,
  15% {
    opacity: 1;
  }

  25%,
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
